ion-split-pane {
  --side-width: 270px;
}

.menu-logo{
  width: 170px;
}


/*@media only screen and (max-width: 767px){ 
  .menu-logo{
    width: 170px;
  }
  Mantein this as an example for future media query validations
}*/