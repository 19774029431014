.NoTextTransform {
    text-transform: none;
    font-size: 9px;
}

.fontSizeError{
    color: red;
    font-size: 12px;
}

.ionHeightGridLogin{
    height: 100%;
}
